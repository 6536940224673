import { App } from 'vue';
import { AutoReload } from '@/shared/plugins/autoreload/auto-reload/AutoReload';
import { AutoReloadModuleOptions } from '@/shared/plugins/autoreload/types';
import { VersionBuilder } from '@/shared/plugins/autoreload/version/Version';

const AutoReloadModule = {

  async install(
    _Vue: App<Element>,
    options: AutoReloadModuleOptions
  ) {
    const versionBuilder = new VersionBuilder();

    const autoReload = new AutoReload({
      ...options,
      versionBuilder: versionBuilder
    });

    autoReload.init();
  }

};

export default AutoReloadModule;
