import { ACTION_TYPE, ROUTE_KEY_TYPE } from '@/shared/utils/constants/common';
import { ROUTE_NAMES } from '@/shared/utils/constants/router.ts';

export const documentsRoutes = [
  {
    path: '/documents',
    name: 'documents',
    meta: {
      keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS
    },
    component: () =>
      import('@/pages/documents/payment-order/PaymentOrderPage.vue')
  },
  {
    path: '/documents/payment-order-create',
    name: 'documents-payment-order-create',
    meta: {
      keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS,
      keyNameAction: ACTION_TYPE.ADD
    },
    component: () =>
      import(
        '@/pages/documents/payment-order/payment-order-create/PaymentOrderCreatePage.vue'
      )
  },
  {
    path: '/documents/payment-order-edit',
    name: 'documents-payment-order-edit',
    meta: {
      keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS,
      keyNameAction: ACTION_TYPE.EDIT
    },
    component: () =>
      import(
        '@/pages/documents/payment-order/payment-order-edit/PaymentOrderEditPage.vue'
      )
  },
  {
    path: '/documents/templates',
    name: ROUTE_NAMES.templates.main,
    meta: {
      keyName: ROUTE_KEY_TYPE.DOCUMENT_TEMPLATES,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/sections/templates/pages/templates-list'
      ),
    children: [
      {
        path: '/documents/templates/create',
        name: ROUTE_NAMES.templates.create,
        meta: {
          keyName: ROUTE_KEY_TYPE.DOCUMENT_TEMPLATES,
          keyNameAction: ACTION_TYPE.ADD
        },
        component: () =>
          import(
            '@/sections/templates/pages/documents-template-create'
          )
      },
      {
        path: '/documents/templates/:id/detail',
        name: ROUTE_NAMES.templates.edit,
        meta: {
          keyName: ROUTE_KEY_TYPE.DOCUMENT_TEMPLATES,
          keyNameAction: ACTION_TYPE.EDIT
        },
        component: () =>
          import(
            '@/sections/templates/pages/documents-template-edit'
          )
      }
    ]
  },
  {
    path: '/documents/in-bank',
    name: 'documents-in-bank',
    meta: {
      keyName: 'DOCUMENTS_IN_BANK'
    },
    component: () =>
      import('@/pages/documents/documents-in-bank/DocumentsInBankPage.vue')
  },
  {
    path: '/documents/in-bank/details',
    name: 'documents-in-bank-details',
    meta: {
      keyName: 'DOCUMENTS_IN_BANK'
    },
    component: () =>
      import(
        '@/pages/documents/documents-in-bank/documents-in-bank-details/IndexPage.vue'
      )
  },
  {
    path: '/swift-transfers',
    name: 'swift-transfers',
    meta: {
      keyName: ROUTE_KEY_TYPE.SWIFT,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/documents/swift-transfers/SwiftTransfersListPage.vue')
  },
  {
    path: '/swift-transfers/create',
    name: 'swift-transfers-create',
    meta: {
      keyName: ROUTE_KEY_TYPE.SWIFT,
      keyNameAction: ACTION_TYPE.INPUT_SWIFT
    },
    component: () =>
      import('@/pages/documents/swift-transfers/SwiftTransfersCreatePage.vue')
  },
  {
    path: '/swift-transfers/edit',
    name: 'swift-transfers-edit',
    meta: {
      keyName: ROUTE_KEY_TYPE.SWIFT,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/documents/swift-transfers/SwiftTransfersEditPage.vue')
  },
  {
    path: '/swift-transfers/instruction',
    name: 'swift-transfers-instruction',
    meta: {
      keyName: ROUTE_KEY_TYPE.SWIFT,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/swift-transfers/SwiftTransfersInstructionPage.vue'
      )
  },
  {
    path: '/currency-exchange',
    name: 'currency-exchange',
    meta: {
      keyName: 'BUY_SELL_CURRENCY_REQUEST'
    },
    component: () =>
      import('@/pages/documents/currency-exchange/CurrencyExchangePage.vue')
  },
  {
    path: '/currency-exchange/create',
    name: 'currency-exchange-create',
    meta: {
      keyName: 'BUY_SELL_CURRENCY_REQUEST'
    },
    component: () =>
      import(
        '@/pages/documents/currency-exchange/create/CurrencyExchangeCreatePage.vue'
      )
  },
  {
    path: '/currency-exchange/:id/detail',
    name: 'currency-exchange-edit',
    meta: {
      keyName: 'BUY_SELL_CURRENCY_REQUEST'
    },
    component: () =>
      import(
        '@/pages/documents/currency-exchange/edit/CurrencyExchangeEditPage.vue'
      )
  },
  {
    path: '/contracts',
    name: 'contracts',
    meta: {
      keyName: 'CONTRACTS'
    },
    component: () =>
      import('@/pages/documents/contracts/ContractsListPage.vue')
  },
  {
    path: '/payment-requirements',
    name: 'payment.index',
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_ELECTRONIC
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/PaymentRequirementPage.vue'
      )
  },
  {
    path: '/payment-requirements/claims',
    name: 'claims.index',
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_ELECTRONIC,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/payment-requirement-claims/PaymentRequirementClaimsPage.vue'
      )
  },
  {
    path: '/payment-requirements/claims/:id',
    name: 'claims.details',
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_ELECTRONIC
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/payment-requirement-claims/payment-requirement-claims-details/PaymentRequirementClaimsDetailsPage.vue'
      )
  },
  {
    path: '/payment-requirements/claims/:id/edit',
    name: 'claims.edit',
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_ELECTRONIC
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/payment-requirement-claims/payment-requirement-claims-edit/PaymentRequirementClaimsEditPage.vue'
      )
  },
  {
    path: '/payment-requirements/claims/create',
    name: 'claims.create',
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_ELECTRONIC
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/payment-requirement-claims/payment-requirement-claims-create/PaymentRequirementClaimsCreatePage.vue'
      )
  },
  /* Не удалять, как только подготовят API нужно будет доделать эту страницу */
  // {
  //   path: '/payment-requirement/claims/:id',
  //   name: 'claims.edit',
  //   meta: {
  //     keyName: 'CONTRACTS',
  //   },
  //   component: () =>
  //     import('@/pages/documents/payment-requirement/payment-requirement-claims/payment-requirement-claims-edit/PaymentRequirementClaimsEditPage.vue'),
  // },
  {
    path: '/payment-requirements/card-index-first',
    name: ROUTE_NAMES.documents.cardIndexesFirstIndex,
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_1,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/card-index-first/CardIndexesFirstPage.vue'
      )
  },
  {
    path: '/payment-requirements/card-index-first/:id',
    name: ROUTE_NAMES.documents.cardIndexesFirstDetails,
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_1,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/card-index-first/details/CardIndexFirstDetailsPage.vue'
      )
  },
  {
    path: '/payment-requirements/card-indexes-second',
    name: ROUTE_NAMES.documents.cardIndexesSecondIndex,
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_2,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/card-index-second/CardIndexSecondPage.vue'
      )
  },
  {
    path: '/payment-requirements/card-index-second/:id',
    name: ROUTE_NAMES.documents.cardIndexesSecondDetails,
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_2,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/card-index-second/details/CardIndexSecondDetailsPage.vue'
      )
  },
  {
    path: '/payment-requirements/closed',
    name: ROUTE_NAMES.documents.paymentRequirementsClosed,
    meta: {
      keyName: 'CONTRACTS'
    },
    component: () =>
      import('@/pages/documents/payment-requirement/closed-payment-requirements-page/ClosedPaymentRequirementsPage.vue')
  },
  {
    path: '/payment-requirements/closed/card-index-first/:id',
    name: ROUTE_NAMES.documents.closedCardIndexesFirstDetails,
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_2,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/closed-payment-requirements-page/closed-card-index-first-details/ClosedCardIndexFirstDetailsPage.vue'
      )
  },
  {
    path: '/payment-requirements/closed/card-index-second/:id',
    name: ROUTE_NAMES.documents.closedCardIndexesSecondDetails,
    meta: {
      keyName: ROUTE_KEY_TYPE.CARDFILE_2,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import(
        '@/pages/documents/payment-requirement/closed-payment-requirements-page/closed-card-index-second-details/ClosedCardIndexSecondDetailsPage.vue'
      )
  },
  {
    path: '/documents/import',
    name: 'document.import.index',
    meta: {
      keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS,
      keyNameAction: ACTION_TYPE.ADD
    },
    component: () =>
      import('@/pages/document-import-page/DocumentImportPage.vue')
  },
  {
    path: '/munis',
    name: 'munis',
    meta: {
      keyName: ROUTE_KEY_TYPE.MUNIS_PAYMENT,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/munis/MunisPage.vue')
  },
  {
    path: '/signing',
    name: ROUTE_NAMES.documents.documentsSigning,
    meta: {
      keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/sections/signing/pages/signing-table')
  },
  {
    path: '/documents/history',
    name: ROUTE_NAMES.documents.documentsHistory,
    meta: {
      keyName: ROUTE_KEY_TYPE.DOCUMENTS_IN_BANK
    },
    component: () =>
      import(
        '@/sections/history/pages/history-table'
      )
  }

];
