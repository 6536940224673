import { RouteRecordRaw } from 'vue-router';
import { PAYMENT_TYPES } from '@/shared/types/payment.types.ts';
import { ACTION_TYPE, ROUTE_KEY_TYPE } from '@/shared/utils/constants/common.ts';
import { ROUTE_NAMES } from '@/shared/utils/constants/router.ts';

export const paymentsRoutes: RouteRecordRaw[] = [
  {
    path: `/payments/${PAYMENT_TYPES.MUNIS}`,
    name: ROUTE_NAMES.payments.munis,
    meta: {
      keyName: ROUTE_KEY_TYPE.MUNIS_PAYMENT,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/pages/munis/MunisPage.vue')
  },
  {
    path: '/payments',
    name: ROUTE_NAMES.payments.main,
    meta: {
      keyName: 'WORK_WITH_DOCUMENTS'
    },
    component: () =>
      import('@/pages/payments/create-payment'),
    children: [
      {
        path: '/payments/:paymentType',
        name: ROUTE_NAMES.payments.createByType,
        meta: {
          keyName: 'WORK_WITH_DOCUMENTS'
        },
        component: () =>
          import('@/pages/payments/create-payment-by-type')
      }
    ]
  }
];
