import { SelectMixedOption } from 'naive-ui/es/select/src/interface';
import i18n from '@/shared/plugins/i18n';
import api from '../plugins/axios';
import {
  ApiVersions,
  IBusinessDay,
  ICountry,
  IPagination,
  IPaymentState,
  IResponseData
} from '../types';
import {
  ICurrencyExchangeOrderStatusItem,
  ICurrencyExchangeOrderStatusListParams,
  IDefaultPaginatedPayloadData,
  IExchangeRate,
  INonPaymentCipher,
  INonPaymentCipherSelectData,
  IPaymentCodesForUnpaidDocs,
  IPaymentCodesForUnpaidDocsSelectData, IPaymentDestinationCode,
  IPaymentType,
  IPaymentTypesSelectData,
  IReportFilters,
  IReportSortType,
  PaymentDestinationCodeType
} from '../types/common.types';
import { ICurrency } from '../types/currency.types';
import { IDocumentType } from '../types/document.types';
import { IPaymentDestinationCodesParams } from '../types/payment.types';
import { IPayrollState } from '../types/payroll.types';
import { ISelectResponseData } from '../types/select.types';

interface IAbsDocumentTypesParams extends IPagination {
  searchString: string;
}

export const fetchOperationInfo = async (): Promise<
  IResponseData<{
    availablePeriodFrom: string;
    availablePeriodTo: string;
    isPayAvailableNow: boolean;
  }>
> => {
  const { data } = await api.get(':baseUrl/k1/operation-datetime-info');

  return data;
};

export const fetchDocumentTypes = async (): Promise<IDocumentType[]> => {
  const { data } = await api.get('/api/common/documentTypes');

  return data.items;
};

export const fetchAbsDocumentTypes = async (
  params: IAbsDocumentTypesParams
): Promise<IDocumentType[]> => {
  const { data } = await api.get('/api/common/abs/documentTypes', {
    params
  });
  return data;
};

export const fetchPaymentStates = async (
  reqData: IDefaultPaginatedPayloadData
): Promise<
  IResponseData<IPaymentState>
> => {
  const { data } = await api.get('/api/common/paymentStates', {
    params: reqData
  });
  return data;
};

export const fetchAbsPaymentStates = async (
  reqData: IDefaultPaginatedPayloadData
): Promise<
  IResponseData<IPaymentState>
> => {
  const { data } = await api.get('/api/common/abs/paymentStates', {
    params: reqData
  });
  return data;
};

export const fetchNonBusinessDays = async (): Promise<
  IResponseData<string>
> => {
  const { data } = await api.get('/api/common/nonBusinessDays');
  return data;
};

export const fetchBusinessDays = async (): Promise<
  IResponseData<IBusinessDay>
> => {
  const { data } = await api.get('/api/common/:branch/businessDayInBranch');
  return data;
};

interface ICountriesParams {
  searchString?: string;
  pageNumber?: number;
  pageSize?: number;
}

export const fetchCountries = async (
  params: ICountriesParams
): Promise<IResponseData<ICountry>> => {
  const { data } = await api.get('/api/common/countries', {
    params
  });
  return data;
};

export const fetchCountriesForSelect = async (
  params: ICountriesParams
): Promise<ISelectResponseData> => {
  const data = await fetchCountries(params);

  return {
    ...data,
    items: data.items.map((v: ICountry) => ({
      label: v.isoId ? `${v.isoId} - ${v.name}` : v.name,
      value: v.id.toString()
    }))
  };
};

export const fetchCurrencies = async (
  params: ICountriesParams
): Promise<IResponseData<ICurrency>> => {
  const { data } = await api.get('/api/common/currencies', {
    params
  });
  return data;
};

export const fetchCurrenciesForSelect = async (
  params: ICountriesParams
): Promise<ISelectResponseData> => {
  const data = await fetchCurrencies(params);

  return {
    ...data,
    items: data.items.map((v: ICurrency) => ({
      ...v,
      label: v.alphaCode + ' - ' + v.name,
      value: v.alphaCode
    }))
  };
};

export const fetchCurrencyExchangeOrderStatusList = async (
  params: ICurrencyExchangeOrderStatusListParams
): Promise<IResponseData<ICurrencyExchangeOrderStatusItem>> => {
  const { data } = await api.get(
    '/api/common/currencies/fcyApplicationStatuses',
    {
      params
    }
  );

  return data;
};

export const fetchCurrencyExchangePurposesList = async (
  params: IDefaultPaginatedPayloadData
): Promise<IResponseData<{ code: string | null; name: string | null }>> => {
  const { data } = await api.get(
    '/api/common/currencies/purposesOfUsingCurrency',
    { params }
  );

  return data;
};

export const fetchSalaryPayrollStates = async (): Promise<
  IResponseData<IPayrollState>
> => {
  const { data } = await api.get('/api/common/salary/payrollStates');
  return data;
};

export const fetchPaymentDestinationCodes = async (
  params: IPaymentDestinationCodesParams
): Promise<IResponseData<IPaymentDestinationCode>> => {
  const { data } = await api.get(
    '/api/common/paymentDestinationCodes-paginated',
    {
      params
    }
  );

  return data;
};

export const fetchPaymentDestinationCodesForSelect = async (
  params: IPaymentDestinationCodesParams
): Promise<ISelectResponseData> => {
  const data = await fetchPaymentDestinationCodes(params);

  const items = data.items.map((v: IPaymentDestinationCode) => ({
    label: v.code === v.name ? v.code : `${v.code || ''} - ${v.name || ''}`,
    value: v.code
  }));

  return {
    ...data,
    items,
    totalPages: data.totalPages
  };
};

export const fetchExchangeRates = async (): Promise<
  IResponseData<IExchangeRate>
> => {
  const { data } = await api.get('/api/common/currencies/exchangeable/actual-rates?branch=:branch', {
    apiVersion: ApiVersions.v3
  });

  return {
    ...data,
    items: data.items.map((v: IExchangeRate) => ({
      ...v,
      courseSell: v.maxRate / 100,
      courseBuy: v.minRate / 100,
      name: v.currency?.name
    }))
  };
};

export const fetchReportSortTypes = async (): Promise<
  IResponseData<IReportSortType>
> => {
  const { data } = await api.get('/api/common/reportSortTypes');
  return data;
};

export const fetchReportFilters = async (): Promise<IResponseData<IReportFilters>> => {
  const { data } = await api.get('/api/common/reportFilters');
  return data;
};

export const fetchReportFiltersForSelect = async (
): Promise<IResponseData<SelectMixedOption>> => {
  const data = await fetchReportFilters();

  const localeNamesByCode: Record<PaymentDestinationCodeType, string> = {
    '1': i18n.global.t('Debit-turnover'),
    '2': i18n.global.t('Credit-turnover'),
    '3': i18n.global.t('all')
  };

  const items = data.items.map((item) => ({
    label: localeNamesByCode[item.code],
    value: item.code
  })).reverse() as SelectMixedOption[];

  return {
    ...data,
    items,
    item: {}
  };
};

export const fetchNonPaymentCipher = async (
  reqData: IDefaultPaginatedPayloadData
): Promise<IResponseData<INonPaymentCipher>
> => {
  const { data } = await api.get('/api/common/nonPaymentCiphers', {
    params: reqData
  });
  return data;
};

export const fetchNonPaymentCipherForSelect = async (
  reqData: IDefaultPaginatedPayloadData
): Promise<INonPaymentCipherSelectData> => {
  const data = await fetchNonPaymentCipher(reqData);
  const items = data.items.map((cipher: INonPaymentCipher) => ({
    label: cipher.name,
    value: cipher.code
  })) as SelectMixedOption[];

  return {
    items,
    totalPages: data.totalPages
  };
};

export const fetchPaymentTypes = async (reqData: IDefaultPaginatedPayloadData): Promise<
  IResponseData<IPaymentType>
> => {
  const { data } = await api.get('/api/common/paymentTypes', {
    params: reqData
  });
  return data;
};

export const fetchPaymentTypesForSelect =
  async (reqData: IDefaultPaginatedPayloadData): Promise<IPaymentTypesSelectData> => {
    const data = await fetchPaymentTypes(reqData);
    const items = data.items.map((paymentType: IPaymentType) => ({
      label: paymentType.name,
      value: paymentType.code
    })) as SelectMixedOption[];

    return {
      items,
      totalPages: data.totalPages
    };
  };

export const fetchPaymentCodesForUnpaidDocs = async (
  reqData: IDefaultPaginatedPayloadData
): Promise<IResponseData<IPaymentCodesForUnpaidDocs>> => {
  const { data } = await api.get('/api/common/paymentCodesForUnpaidDoc', {
    params: reqData
  });
  return data;
};

export const fetchPaymentCodesForUnpaidDocsForSelect = async (
  reqData: IDefaultPaginatedPayloadData
): Promise<IPaymentCodesForUnpaidDocsSelectData> => {
  const data = await fetchPaymentCodesForUnpaidDocs(reqData);
  const items = data.items.map((paymentType: IPaymentType) => ({
    label: paymentType.name,
    value: paymentType.code
  })) as SelectMixedOption[];

  return {
    items,
    totalPages: data.totalPages
  };
};
