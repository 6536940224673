import axios from 'axios';
import { VersionOptions, VersionType } from '@/shared/plugins/autoreload/types';

export class VersionBuilder {

  async getVersion(): Promise<VersionType | undefined> {
    const { data: versionData } = await axios.get(`/version.json?timestamp=${new Date().getTime()}`);
    if (versionData) {
      return this.generateVersion(versionData);
    } else {
      console.error('Упс.. Не удалось получить файл version json');
      return undefined;
    }
  }

  generateVersion(options: VersionOptions) {
    return new Version(options);
  }
}

export class Version {
  appVersion: string;

  constructor(options: VersionOptions) {
    this.appVersion = options.appVersion;
  }
}
