import axios from 'axios';
import api from '@/shared/plugins/axios';
import type {
  CreateVirtualSignatureParams,
  StyxApiResult,
  StyxCertToDBResponse,
  StyxConfirmSMSParams,
  StyxGetVirtualCSRResponse,
  StyxListResult,
  StyxSendSMSReponse,
  StyxSubject } from '@/shared/types/styx.types';
import { ApiDigitalSignature } from '.';
import type { IApiErrorData } from './manager/managers.types';

const localApi = axios.create({
  baseURL: 'http://localhost:6210'
});

const checkError = (error: null | IApiErrorData) => {
  if (error) {
    throw error;
  }
};

export const sendSMSVirtualSignature = async (): Promise<StyxApiResult<StyxSendSMSReponse>> => {
  const { data } = await api.get<StyxApiResult<StyxSendSMSReponse>>(':baseUrl/signature/styx/sendSMS');

  return data;
};

export const confirmSMSVirtualSignature = async ({
  otpCode,
  userId,
  confirmToken
}: StyxConfirmSMSParams): Promise<StyxSubject> => {
  // Подтверждаем смс
  const { data } = await api.put<StyxApiResult<StyxSubject>>(':baseUrl/signature/styx/cert/confirmSMS', {
    userId,
    confirmToken,
    confirmCode: otpCode
  });

  // Если неверный код вводится, то апи может вернить null
  if (!data.result) {
    throw new Error();
  }
  checkError(data.error);

  return data.result;
};

export const createVirtualSignature = async ({
  confirmCode,
  styxSubject
}: CreateVirtualSignatureParams): Promise<void> => {
  const { certInfos: oldCertInfos } = await ApiDigitalSignature.fetchCertificates();

  // Локальный запрос на создание подписи
  const { data: CSRResponse } = await localApi.post<StyxGetVirtualCSRResponse>('/crypto/getVirtualCSR', styxSubject);

  if (CSRResponse.status === 'error' || !CSRResponse.status) {
    throw new Error();
  }

  // Подтверждение подписи
  const { data: { result: localCSRToken, error: confirmCertError } } = await api.post<StyxApiResult<string>>(':baseUrl/signature/styx/cert/confirmCert', {
    var1: CSRResponse.var1
  });

  checkError(confirmCertError);

  // Сохраняем подпись локально
  await localApi.post<StyxCertToDBResponse>('/crypto/certToDB', {
    pin: confirmCode,
    obj: localCSRToken,
    container: CSRResponse.var2,
    subject: styxSubject
  });

  // Получаем список локальных подписей
  const { certInfos } = await ApiDigitalSignature.fetchCertificates();

  // !certInfos[0] значит, что нет подписей и первый элемент это null
  if (!certInfos[0]) {
    // мб ошибка должна быть?
  }

  const parsedCertInfos = oldCertInfos.map((cert) => {
    if (cert) {
      return cert.serialnumber;
    }
    return '';
  });
  const filteredCertInfo = certInfos.filter(cert => !parsedCertInfos.includes(cert?.serialnumber || ''));
  const certInfo = filteredCertInfo[filteredCertInfo.length - 1];

  if (certInfo) {
    await api.post(':baseUrl/signature/styx/cert/saveCert', {
      subject: styxSubject,
      saveModel: {
        thumbprint: certInfo.thumbprint,
        pfx: localCSRToken,
        serialNumber: certInfo.serialnumber,
        passwordHash: confirmCode,
        issuer: certInfo.issuer,
        certificate: certInfo.pubCert,
        certificateTo: certInfo.notafter,
        certificateFrom: certInfo.notbefore
      }
    });
  } else {
    throw new Error('could-not-create-virtual-signature-try-later');
  }
};

export const deleteVirtualSignature = async (id: number) => {
  const { data } = await api.delete(`:baseUrl/signature/styx/cert/delete/${id}`);

  return data;
};

export const fetchVirtualSignatures = async () => {
  const { data } = await api.get<StyxApiResult<StyxListResult[]>>(':baseUrl/signature/styx/cert/getList');

  return data;
};
