import api from '@/shared/plugins/axios';
import {
  ApiVersions,
  IBusinessBranch,
  IBusinessSettings,
  ICardRequisites,
  IResponseData,
  PaymentCard,
  PaymentCardMapped
} from '@/shared/types';
import { PaymentCardsParams, PaymentCardStatuses } from '../types/business.types';
import { CurrencyAlphaCodeNumber } from '../types/currency.types';
import { mapClientCardsResponse } from '../utils/mapper/business-api';

export const fetchClientCards = async (
  params?: PaymentCardsParams,
  signal?: AbortSignal
): Promise<
  IResponseData<PaymentCard>
> => {
  const { data } = await api.get(':baseUrl/paymentCards', {
    params,
    signal,
    apiVersion: ApiVersions.v3
  });

  return data;
};

export const fetchClientCardsMapped = async (params?: PaymentCardsParams): Promise<
  IResponseData<PaymentCardMapped>
> => {
  const data = await fetchClientCards(params);

  return mapClientCardsResponse(data);
};

export const fetchClientCardsForStatements = async ({ currencyIds }: { currencyIds?: CurrencyAlphaCodeNumber[] } = {}): Promise<
  IResponseData<PaymentCard>
> => {
  return fetchClientCardsMapped({
    states: [
      PaymentCardStatuses.Active,
      PaymentCardStatuses.Blocked,
      PaymentCardStatuses.Inactive
    ],
    currencyIds
  });
};

export const fetchCardRequisites = async (
  cardNumber: string
): Promise<IResponseData<ICardRequisites>> => {
  const { data } = await api.get(`/api/cards/${cardNumber}/requisites`);
  return data;
};

export const onFetchBusinessList = async (): Promise<
  IResponseData<IBusinessBranch>
> => {
  const { data } = await api.get('/api/business/list', {
    apiVersion: ApiVersions.v3
  });

  return data;
};

export const fetchBusinessDetailInfo = async (): Promise<
  IResponseData<IBusinessBranch>
> => {
  const { data } = await api.get(':baseUrl/detailed-info');
  return data;
};

export const fetchBusinessSettings = async (): Promise<
  IResponseData<IBusinessSettings>
> => {
  const { data } = await api.get(':baseUrl/settings');
  return data;
};

export const fetchActiveClientCards = async (): Promise<
  IResponseData<PaymentCard>
> => {
  return await fetchClientCards({
    states: PaymentCardStatuses.Active
  });
};

export const fetchClientCardByMask = async (maskedCard: string): Promise<
  IResponseData<PaymentCard>
> => {
  const { data } = await api.get(`:baseUrl/paymentCards/${maskedCard}`, {
    apiVersion: ApiVersions.v3
  });

  return data;
};
