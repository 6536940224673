import { Value } from 'naive-ui/es/date-picker/src/interface';
import i18n from '@/shared/plugins/i18n';

type LocaleTypes = 'ru' | 'uz' | 'en';

interface ILocale {
  days: string[];
  daysShort: string[];
  months: string[];
  monthsGenitive: string[];
  monthsShort: string[];
  firstDayOfWeek: number;
  format24h: boolean;
  pluralDay: string;
}
interface ILocalesMap {
  ru: ILocale;
  uz: ILocale;
  en: ILocale;
}

interface IFormatter {
  YY: (date: Date, dateLocale: ILocale) => string;
  YYYY: (date: Date, dateLocale?: ILocale) => number;
  MM: (date: Date) => void;
  MMM: (date: Date, dateLocale: ILocale) => string;
  MMMM: (date: Date, dateLocale: ILocale) => string;
  MMMMM: (date: Date, dateLocale: ILocale) => string;
  DD: (date: Date) => void;
  d: (date: Date) => void;
  dd: (date: Date, dateLocale: ILocale) => string;
  ddd: (date: Date, dateLocale: ILocale) => string;
  dddd: (date: Date, dateLocale: ILocale) => string;
  HH: (date: Date) => string;
  h: (date: Date) => number;
  hh: (date: Date) => string;
  mm: (date: Date) => string;
  ss: (date: Date) => string;
}

type dateShortcutNamings =
  | 'SECONDS_IN_MINUTE'
  | 'MINUTES_IN_HOURS'
  | 'MILLISECONDS_IN_SECOND'
  | 'HOURS_IN_DAY'
  | 'MILLISECONDS_IN_HOUR'
  | 'THIRTY_ONE_DAYS_IN_MILLISECONDS'
  | 'MILLISECONDS_PER_DAY';
export type dateShortcuts = Record<dateShortcutNamings, number>;

const locales: ILocalesMap = {
  ru: {
    days: 'Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота'.split('_'),
    daysShort: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
    months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
    monthsGenitive: 'Января_Февраля_Марта_Апреля_Мая_Июня_Июля_Августа_Сентября_Октября_Ноября_Декабря'.split('_'),
    monthsShort: 'Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split('_'),
    firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'дней'
  },
  uz: {
    days: 'Yakshanba_Dushanba_Seshanba_Chorshanba_Payshanba_Juma_Shanba'.split('_'),
    daysShort: 'Yak_Du_Se_Chor_Pay_Juma_Shanba'.split('_'),
    months: 'Yanvar_Fevral_Mart_Aprel_May_Iyun_Iyul_Avgust_Sentabr_Oktabr_Noyabr_Dekabr'.split('_'),
    monthsGenitive: 'Yanvar_Fevral_Mart_Aprel_May_Iyun_Iyul_Avgust_Sentabr_Oktabr_Noyabr_Dekabr'.split('_'),
    monthsShort: 'Yan_Fev_Mart_Apr_May_Iyun_Iyul_Avg_Sen_Okt_Noy_Dek'.split('_'),
    firstDayOfWeek: 1, // 0-6, 0 - Yakshanba, 1 Dushanba, ...
    format24h: true,
    pluralDay: 'Kunlar'
  },
  en: {
    days: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
    daysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsGenitive: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    firstDayOfWeek: 0, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: false,
    pluralDay: 'days'
  }
};
const defaultLocale = locales.ru;
const defaultMask = 'DD.MM.YYYY';
const token = /\[((?:[^\]\\]|\\]|\\)*)\]|d{1,4}|M{1,5}|m{1,2}|w{1,2}|Qo|Do|D{1,4}|YY(?:YY)?|H{1,2}|h{1,2}|s{1,2}|S{1,3}|Z{1,2}|a{1,2}|[AQExX]/g;

const formatter: IFormatter = {
  // Year: 00, 01, ..., 99
  YY(date, dateLocale) {
    // workaround for < 1900 with new Date()
    const y = this.YYYY(date, dateLocale) % 100;
    return y >= 0 ? pad(y) : '-' + pad(Math.abs(y));
  },

  // Year: 1900, 1901, ..., 2099
  YYYY(date, _dateLocale) {
    // workaround for < 1900 with new Date()
    return date.getFullYear();
  },

  // Month: 01, 02, ..., 12
  MM(date) {
    return pad(date.getMonth() + 1);
  },

  // Month Short Name: Jan, Feb, ...
  MMM(date, dateLocale) {
    return dateLocale.monthsShort[date.getMonth()];
  },

  // Month Name: January, February, ...
  MMMM(date, dateLocale) {
    return dateLocale.months[date.getMonth()];
  },

  // Month Name: Января, Февраля, Марта ...
  MMMMM(date, dateLocale) {
    return dateLocale.monthsGenitive[date.getMonth()];
  },

  // Day of month: 01, 02, ..., 31
  DD(date) {
    return pad(date.getDate());
  },

  // Day of week: 0, 1, ..., 6
  d(date) {
    return date.getDay();
  },

  // Day of week: Su, Mo, ...
  dd(date, dateLocale) {
    return this.dddd(date, dateLocale).slice(0, 2);
  },

  // Day of week: Sun, Mon, ...
  ddd(date, dateLocale) {
    return dateLocale.daysShort[date.getDay()];
  },

  // Day of week: Sunday, Monday, ...
  dddd(date, dateLocale) {
    return dateLocale.days[date.getDay()];
  },

  // Hour: 00, 01, ..., 23
  HH(date) {
    return pad(date.getHours());
  },

  // Hour: 1, 2, ..., 12
  h(date) {
    const hours = date.getHours() || 12;

    return hours > 12 ? hours % 12 : hours;
  },

  // Hour: 01, 02, ..., 12
  hh(date) {
    return pad(this.h(date));
  },

  // Minute: 00, 01, ..., 59
  mm(date) {
    return pad(date.getMinutes());
  },

  // Second: 00, 01, ..., 59
  ss(date) {
    return pad(date.getSeconds());
  }
};

const dateShortcuts = {
  MINUTES_IN_HOURS: 60,
  SECONDS_IN_MINUTE: 60,
  MILLISECONDS_IN_SECOND: 1000,
  HOURS_IN_DAY: 24
} as dateShortcuts;

dateShortcuts.MILLISECONDS_IN_HOUR =
  dateShortcuts.MINUTES_IN_HOURS *
  dateShortcuts.SECONDS_IN_MINUTE *
  dateShortcuts.MILLISECONDS_IN_SECOND;

dateShortcuts.MILLISECONDS_PER_DAY =
  dateShortcuts.HOURS_IN_DAY * dateShortcuts.MILLISECONDS_IN_HOUR;

dateShortcuts.THIRTY_ONE_DAYS_IN_MILLISECONDS = 31 * dateShortcuts.MILLISECONDS_PER_DAY;

function pad(v: number, length = 2, char = '0') {
  if (v === void 0 || v === null) {
    return v;
  }

  const val = '' + v;
  return val.length >= length
    ? val
    : new Array(length - val.length + 1).join(char) + val;
}

function getDateLocale(dateLocale?: LocaleTypes): ILocale {
  const locale = i18n.global.locale.value as LocaleTypes;
  return locales[dateLocale || locale] || defaultLocale;
}

function parseDateString(val: string | number): string | number {

  if (typeof val !== 'string') return val;

  const dateTimeList: string[] = val.split('+');
  const [ dateTime ] = dateTimeList;

  return dateTime;
}

function formatDate(
  val: string | number | null | undefined,
  mask: string = defaultMask,
  dateLocale?: LocaleTypes
): string {
  if (!val) return '';

  const date: Date = new Date(parseDateString(val));

  if (isNaN(Number(date))) return '';

  const locale = getDateLocale(dateLocale);

  return mask.replace(token, (match, text) => {
    if (match in formatter)
      return formatter[match as keyof IFormatter](date, locale);

    return text === void 0
      ? match
      : text.split('\\]').join(']');
  });
}

function formatDateToUTC(
  date?: Value | string | number | null,
  endDay = false
) {
  if (!date) return;

  if (typeof date !== 'number') {
    date = date.toString();
  }

  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const dateTime = new Date(date).getTime();
  const newDate = new Date(dateTime - tzoffset);

  if (endDay) newDate.setUTCHours(23, 59, 59, 999);
  const localISOTime = newDate.toISOString();

  return localISOTime.split('.')[0]; // @TODO: Вернуть миллисекунды когда починят API отчетов
}

function getTimeRemaining() {
  const endDayMilliseconds = new Date().setHours(23, 59, 59, 999);

  const timestampLeftEndOfDay = endDayMilliseconds - Date.now();

  const hoursLeft = timestampLeftEndOfDay / dateShortcuts.MILLISECONDS_IN_HOUR;
  const minutesLeft = hoursLeft * dateShortcuts.MINUTES_IN_HOURS;
  const secondsLeft = minutesLeft * dateShortcuts.SECONDS_IN_MINUTE;
  const millisecondsLeft = secondsLeft * dateShortcuts.MILLISECONDS_IN_SECOND;

  return {
    milliseconds: millisecondsLeft
  };
}

function dateDisabled(ts: number) {
  return ts > Date.now();
}

function previousDateDisabled(ts: number) {
  const date = new Date(ts).getDate();
  const month = new Date(ts).getMonth() + 1;
  const year = new Date(ts).getFullYear();
  const today = new Date();
  const currentDate = today.getDate();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  return date < currentDate && month <= currentMonth && year <= currentYear
    || month < currentMonth && year <= currentYear
    || year < currentYear;
}

function getDaysInMonth(month: number, year: number): Date[] {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export {
  dateDisabled,
  dateShortcuts,
  formatDate,
  formatDateToUTC,
  getDateLocale,
  getDaysInMonth,
  getTimeRemaining,
  type ILocale,
  parseDateString,
  previousDateDisabled
};
