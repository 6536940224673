import { Router } from 'vue-router';
import { AutoReloadOptionsType, VersionGeneratorType } from '@/shared/plugins/autoreload/types';

export class AutoReload {
  router: Router;

  lastVersion: null | string;

  checkInterval: number;

  timer: null | ReturnType<typeof setTimeout>;

  versionBuilder: VersionGeneratorType;

  constructor(options: AutoReloadOptionsType) {
    this.router = options.router;
    this.versionBuilder = options.versionBuilder;
    this.lastVersion = null;
    this.checkInterval = 60;
    this.timer = null;
  }

  async init() {
    const version = await this.versionBuilder.getVersion();

    if (!version) return false;

    this.lastVersion = version.appVersion;

    if (this.lastVersion) {
      this.timer = setInterval(async () => {
        await this.check();
      }, this.checkInterval * 1000);
    }

    this.router.beforeEach((to, _from, next) => {
      this.check(this.router.resolve(to).href);
      next();
    });

  }

  async check(href?: string) {
    const version = await this.versionBuilder.getVersion();

    if (!version) return false;

    if (this.lastVersion !== version.appVersion) {

      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }

      this.lastVersion = version.appVersion;

      this.reload(href);
    }
  }

  reload(href?: string) {
    if (href) {
      window.location.href = href;
    } else {
      window.location.reload();
    }
  }

}
