import { UnwrapRef } from 'vue';
import { AxiosResponse } from 'axios';
import { ICurrencyConversionListItem } from '@/shared/types/currency-conversion.types';
import { ICurrencyExchangeDetail } from '@/shared/types/currency-exchange.types';
import { IResponseData, ISignPayloadObject, Permissions } from '@/shared/types/index.ts';
import { MunisPayment } from '@/shared/types/munis.types';
import { IPayroll } from '@/shared/types/payroll.types';
import { ISwiftTransfer } from '@/shared/types/swift.types';
import { PLATFORM_KEY_TYPE } from '../utils/constants/global.config';
import { CurrencyAlphaCode } from './currency.types';
import { DocumentDate, EDocumentType, IDocument } from './document.types';

export interface IPaymentDestinationCodesParams {
  documentType?: EDocumentType;
  searchString?: string;
  pageNumber?: number;
  pageSize?: number;
}

export interface IPaymentOrdersParams {
  pageNumber: number;
  pageSize: number;
  id?: string;
  state?: number | number[];
  dateFrom?: string;
  dateTo?: string;
  documentType?: EDocumentType;
  senderAccountNumber?: string;
  receiverBranchCode?: string;
  receiverAccountNumber?: string;
  paymentPurposeCode?: string;
  paymentPurpose?: string;
  amount?: string | number;
  paymentNumber?: string;
}
export interface IPaymentOrdersTemplateParams {
  pageNumber?: number;
  pageSize?: number;
  isAnor?: boolean;
}

export interface IPaymentOrderPayload extends IDocument {}

export interface ISignPaymentOrderPayload {
  keyType: PLATFORM_KEY_TYPE;
  objects: ISignPayloadObject[];
}

export enum PAYMENT_TYPES {
  TO_ACCOUNT = 'to-account',
  TO_CARD = 'to-card',
  TO_BUDGET = 'to-budget',
  TO_TREASURY = 'to-treasury',
  MUNIS = 'munis',
  SWIFT = 'swift',
  BUY_CURRENCY = 'buy-currency',
  SELL_CURRENCY = 'sell-currency',
  CURRENCY_CONVERSION = 'currency-conversion'
}

export enum PAYMENT_STATUSES {
  CREATED = 'created',
  APPROVED = 'approved',
  SIGNED = 'signed'
}

export interface PaymentToAccountPayload {
  paymentNumber: string;
  senderBranch: string;
  documentDate: string;
  senderAccountNumber: string;
  receiverBranch: string;
  receiverAccountNumber: string;
  receiverName: string;
  receiverInnOrPinfl?: string;
  paymentPurpose: string;
  paymentPurposeCode: string;
  amount: number;
  isAnor: boolean;
}

export interface PaymentSuccessData {
  id: number;
  paymentType: PAYMENT_TYPES;
  paymentStatus: PAYMENT_STATUSES;
  paymentPermissions?: Permissions;
  currency: string;
  amount: number;
  textToSign: string;
}

export type PaymentOrdersToSignParams = Pick<IPaymentOrdersParams, 'pageNumber' | 'pageSize'>

export interface PaymentToCardPayload {
  paymentNumber: string;
  documentDate: string;
  senderBranch: string;
  senderAccountNumber: string;
  cardAccount?: string;
  cardNumber: string;
  receiverBranch: string;
  receiverAccountNumber: string;
  receiverName: string;
  receiverInnOrPinfl?: string;
  paymentPurpose: string;
  paymentPurposeCode: string;
  amount: number;
}

export interface PaymentToTreasuryPayload {
  paymentNumber: string;
  documentDate: string;
  senderBranch: string;
  senderAccountNumber: string;
  budgetAccountNumber: string;
  paymentPurpose: string;
  paymentPurposeCode: string;
  amount: number;
  isAnor: boolean;
}

export interface PaymentToBudgetPayload {
  paymentNumber: string;
  documentDate: string;
  senderBranch: string;
  senderAccountNumber: string;
  budgetAccountNumber: string;
  budgetInn: string;
  paymentPurpose: string;
  paymentPurposeCode: string;
  amount: number;
  isAnor: boolean;
}

export interface SharedPaymentForm {
  paymentNumber: string | null;
  documentDate: DocumentDate;
  senderAccountNumber: string | null;
  senderAccountBalance: number | null;
  senderAccountCurrency: CurrencyAlphaCode | null;
  amount: number | null;
  paymentPurpose: string | null;
  paymentPurposeCode: string | null;
  purposeCodeName: string | null;
}

export interface SharedPaymentOptionsPayload {
  amount: number | null;
}

export interface SharedPaymentOptions<T extends SharedPaymentForm, U extends SharedPaymentOptionsPayload> {
  paymentFormDefault: T;
  mapTemplateFormBeforeSave: (model: Omit<UnwrapRef<T>, 'documentDate'>) => IDocument;
  mapCreatePayload: (model: UnwrapRef<T>) => U;
  transferTypeCreate: EDocumentType;
  paymentTypeCreate: PAYMENT_TYPES;
  createPaymentRequest: (payload: U, model: UnwrapRef<T>) => Promise<AxiosResponse<IResponseData<number>>>;
  updatePaymentRequest: (paymentId: string, payload: U, model: UnwrapRef<T>) => Promise<AxiosResponse<IResponseData<number>>>;
  mapFormData: (payment: IDocument) => UnwrapRef<T>;
}

export interface SharedPaymentEmit {
  (event: 'saveTemplate', document: IDocument): void;
  (event: 'paymentCreated', value: PaymentSuccessData): void;
  (event: 'ready', value: boolean): void;
}

export interface MassSignDataForModal {
  amount: Record<string, number>,
  count: Record<string, number>,
  fromDate: string,
  toDate: string
}

export const enum PaymentsInBankStates {
  Delayed = -1,
  EnteredWithErrors,
  Entered,
  CarriedOut,
  InProcess,
}

export type DocumentTypeToPaymentType = Record<EDocumentType, PAYMENT_TYPES>

type SumPayments = IDocument | MunisPayment | IPayroll

type CurrencyPayments = ISwiftTransfer | ICurrencyExchangeDetail | ICurrencyConversionListItem

export type AnyPayment = SumPayments | CurrencyPayments
