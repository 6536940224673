import { ICertificate } from '@/shared/types';

export const checkCertificates = (
  tokenCertificate: ICertificate
) => (
  confirmCertificate: string
) => {
  const parts = confirmCertificate
    .split('*****')
    .map(part => part.toUpperCase());

  return tokenCertificate.serialnumber.startsWith(parts[0])
    && tokenCertificate.serialnumber.endsWith(parts[1]);
};
