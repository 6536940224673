export enum CurrencyAlphaCode {
  UZS = 'UZS',
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
  CNY = 'CNY',
  KZT = 'KZT',
  GBP = 'GBP',
  AED = 'AED',
  TRY = 'TRY',
  JPY = 'JPY',
  CHF = 'CHF',
  TRL = 'TRL'
}

export const enum CurrencyAlphaCodeNumber {
  // UZS2 = '860',
  UZS3 = '0',
  UZS = '000',
  USD = '840',
  EUR = '978',
  RUB = '643',
  CNY = '156',
  KZT = '398',
  GBP = '826',
  AED = '784',
  TRY = '949',
  JPY = '392',
  CHF = '756',
}

export interface ICurrency {
  alphaCode: CurrencyAlphaCode;
  name: string;
  numericCode: CurrencyAlphaCodeNumber;
  scale: string;
}

export interface ICurrencyParams {
  searchString?: string;
  pageNumber?: number;
  pageSize?: number;
}
export interface ICurrencyOperationTypes {
  id: string;
  name: string;
}
export interface ICurrencyRate {
  buyRate: number;
  sellRate: number;
  currentDateTime: string;
}

export interface IPurposesOfUsingCurrencyTypes {
  name: string;
  code: string;
}
export interface ISourcesOfForeignExchange {
  name: string;
  code: string;
}
